import { render, staticRenderFns } from "./SearchJument.vue?vue&type=template&id=0987a2a5&"
import script from "./SearchJument.vue?vue&type=script&lang=js&"
export * from "./SearchJument.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports